import React from 'react';
import Course from './Course.js'
import {
  Router, Route
  //BrowserRouter as Router, Route
} from 'react-router-dom'
import Introduction from './Introduction.js';
import SeeOne from './SeeOne/SeeOne.js';
import DoOne from './DoOne/DoOne.js';
import TeachOne from './TeachOne/TeachOne.js';
import TeachOneCongrats from './TeachOne/TeachOneCongrats.js';
import TeachOneMoreResources from './TeachOne/TeachOneMoreResources.js';
import history from './history'

import ReactGA from "react-ga4";

//ReactGA.initialize("G-PYZCQ46SG8");


class MifeApp extends React.Component {
  constructor(props) {
    super(props)

    var urlFromCME = this.searchUrlParams('cme') === 'true' ? true : false;
    var timecode = this.searchUrlParams('time') 

    this.state = {
      fromCME: urlFromCME,
      timecode: timecode,
      previouslyViewedMedGuide: false,
      startFromEnd: false
    }

    if (urlFromCME) {
      history.push("/home")
    }
  }

  searchUrlParams = function(name) {
    var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
       return null;
    }
    else{
       return decodeURI(results[1]) || 0;
    }
  }  

  setViewedMedicationGuide = (value) => {
    this.setState({previouslyViewedMedGuide: value})
  }
  
  handleNextPracticeSection = () => {
    // This method should probably also handle the transistion from the end of DoOne to TeachOne
    this.setState({startFromEnd: false})
    history.push("/doone/practice2")
  }

  handlePrevPracticeSection = () => {
    this.setState({startFromEnd: true})
    history.push("/doone/practice1")
  }

  render () {
    const { previouslyViewedMedGuide, timecode, startFromEnd} = this.state;
    return (
      <Router history={history} >
        <Route exact path='/' render={() => <Introduction />} />
        <Route path='/home' render={() => <Course />} />
        <Route exact path='/seeone' render={() => <SeeOne previouslyViewedMedGuide={previouslyViewedMedGuide} setViewedMedicationGuide={this.setViewedMedicationGuide} />} />
        <Route path='/seeone/video2' render={() => <SeeOne video="2" previouslyViewedMedGuide={previouslyViewedMedGuide} timecode={timecode} setViewedMedicationGuide={this.setViewedMedicationGuide}/>} />
        <Route path='/seeone/video3' render={() => <SeeOne video="3" timecode={timecode}/>} />
        <Route exact path='/doone' render={() => <DoOne />} />
        <Route exact path='/doone/practice1' render={() => <DoOne section="practice1"
                                                            history={history}
                                                            startFromEnd={startFromEnd}
                                                            prevSection={this.handlePrevPracticeSection}
                                                            nextSection={this.handleNextPracticeSection}/>} />
        <Route exact path='/doone/practice2' render={() => <DoOne section="practice2"
                                                            history={history}
                                                            prevSection={this.handlePrevPracticeSection}
                                                            nextSection={this.handleNextPracticeSection}/>} />
        <Route path='/teachone/congrats' render={() => <TeachOneCongrats />} />
        <Route path='/teachone/more' render={() => <TeachOneMoreResources />} />
        <Route exact path='/teachone' render={() => <TeachOne />} />
        <Route path='/teachone/quiz' render={() => <TeachOne section="quiz" history={history}/>} />
      </Router>
    );
  }
}

export default MifeApp;
