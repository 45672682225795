import React from "react";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import SeeOneIcon from "../Home_SeeOneIcon.png";
import DoOneIcon from "../Home_DoOneIcon.png";
import TeachOneIcon from "../Home_TeachOneIcon.png";
import { CONTACT_HREF } from "../shared/utils.js";

function NavMenu(props) {
  let showTeachOne = props.showTeachOne ? true : false;
  let showSeeOne = props.showSeeOne ? true : false;
  let showDoOne = props.showDoOne ? true : false;
  return (
    <div className="NavMenu">
      <div className="navlinks">
        {showSeeOne && (
          <Link to="/seeone">
            <img src={SeeOneIcon} alt="See One" />
          </Link>
        )}
        {showDoOne && (
          <Link to="/doone">
            <img src={DoOneIcon} alt="Do One" />
          </Link>
        )}
        {showTeachOne && (
          <Link to="/teachone">
            <img src={TeachOneIcon} alt="Teach One" />
          </Link>
        )}
        <a href="https://cme-reg.configio.com/pd/4274/medication-abortion-in-primary-care?cid=2460&returncom=productlist&source=search">
          CME
        </a>
        <Link to="/">About</Link>
        <a href={CONTACT_HREF}>Feedback</a>
      </div>
    </div>
  );
}

export default NavMenu;
