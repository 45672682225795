import React from 'react';
import './TeachOneQuiz.scss';
import text from './quiz.json'
import QuizNav from './QuizNav.js';
import MifepristoneOverviewDoc from '../DoOne/MifepristoneOverview.docx';
//import ReactGA from 'react-ga';

class TeachOneQuiz extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      questionNum: 0,
      userAnswer: null,
      showAnswer: false,
      showReferences: false
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    //ReactGA.pageview("/teachone/quiz")
  }

  handleNextQuestion = (e) => {
    if (this.state.questionNum >= 19) {
      this.props.history.push("/teachone/congrats");
    } else {
      this.setState({questionNum: (this.state.questionNum + 1),
                     showAnswer: false,
                     userAnswer: null,
                     showReferences: false
                     })
      window.scrollTo(0, 0);
    }
  }

  handleShowAnswer = () => {
    this.setState({showAnswer: true});
  }

  handleAnswerClick (answerNum) {
    this.setState({userAnswer: answerNum});
  }

  handleShowReferences = () => {
    this.setState({showReferences: !this.state.showReferences});
  }
    
  navClickHandler = (questionNum) => {
    this.setState({questionNum: questionNum,
                   showAnswer: false,
                   userAnswer: null,
                   showReferences: false })
    window.scrollTo(0, 0);
  }

  getAnswerOptions = (questionNum, userAnswer) => {
    const answerOptions = text["questions"][questionNum]["answer_options"]
    return (
      <form className="answerOptions">
        {
          answerOptions.map((option, i) => (
            <label key={i}>
              <input type="radio" 
                     onChange={this.handleAnswerClick.bind(this, i)} 
                     checked={userAnswer===i} 
                     value={option}
                     key={i} />
               {option}
            </label>
          ))
        }
      </form>
    );
  }

  convertLink = (someText) => {
    var regex =/(http.*)/gi; 
    return someText.replace(regex, "<a href=$1 target=\"_blank\">$1</a>")
  }

  getAnswerReferences = (questionNum) => {
    const answerReferences = text["questions"][questionNum]["answer_references"]
    return (
      <div className="references">
        {
          answerReferences.map((reference, i) => (
            <div className="reference" key={i} dangerouslySetInnerHTML={{ __html: "<sup>" + (i+1).toString() + "</sup> " + this.convertLink(reference)}}></div>
          ))
        }
      </div>
    );
  }

  render () {
    const { questionNum, userAnswer, showAnswer, showReferences } = this.state

    const questionText = text["questions"][questionNum]["text"]
    const answerOptions = this.getAnswerOptions(questionNum, userAnswer)
    const answerText = text["questions"][questionNum]["answer_text"]
    const answerReferences = this.getAnswerReferences(questionNum)
    const correctAnswer = text["questions"][questionNum]["correct_answer"]
    const correctness = userAnswer === correctAnswer ? "Correct!" : "Incorrect."
        
    return (
      <div className="TeachOneQuiz">
        <div className="question">
          <div className="mobileOnly">
            <p className="questionPrefaceTeach">
              Teach!
            </p>
          </div>
          <p className="questionPrefaceHeader">
            Let's Play 20 Questions
          </p>
          <p className="questionText">
            {questionNum+1}. &nbsp;
            {questionText}
          </p>
          <div className="hint">
            <span>(Hint: check </span>
            <a href={MifepristoneOverviewDoc} download>this overview</a>
            <span>)</span>
          </div>
          <div className="selectAnswer">
            <p>
              SELECT ANSWER
            </p>
          </div>
          {answerOptions}
          <div className="answer">
            { showAnswer ?
              <div className="showAnswer">
                <div className="answer">{correctness}</div>
                <div className="answerText" dangerouslySetInnerHTML={{ __html: answerText}}></div>
                <div className="referenceSection">
                  { showReferences ?
                    <div>
                      <div className="showShowReferences" onClick={this.handleShowReferences}>
                        References
                      <div className="arrow-down"></div>
                    </div>
                      {answerReferences}
                    </div> :
                    <div className="showShowReferences" onClick={this.handleShowReferences}>
                      References
                      <div className="arrow-right"></div>
                    </div>
                  }
                </div>
                <button id="next" onClick={this.handleNextQuestion}>
                  <p>NEXT</p>
                  <div className="arrow-right" />
                </button>
              </div> :
              <button id="showShowAnswer" onClick={this.handleShowAnswer}>
                <p>SHOW ANSWER</p>
                <div className="arrow-right" />
              </button>
            }
          </div>

        </div>
        <QuizNav questionNum={questionNum} clickHandler={this.navClickHandler} />
      </div>
    );
  }
}

export default TeachOneQuiz;
