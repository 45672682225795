import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
import SeeOneIcon from './SeeOne_wht.png';
import DoOneIcon from './DoOne_wht.png';
import TeachOneIcon from './TeachOne_wht.png';
import DownloadFile from '../TeachOne/MifepristoneTestAnswers.docx';

import { EMAIL_HREF } from '../shared/utils.js';

function teachOneSubNav(props) {
  let quiz_class = "enabled";
  let more_class = "enabled";
  switch (props.section) {
    case "quiz":
      quiz_class = "disabled";
      break;
    case "more":
      more_class = "disabled";
      break;
    default:
      break
  }

  return (
    <div className="teachSubNav">
      <div className="headerLink quiz">
        <Link to="/teachone/quiz" className={quiz_class}>
          Quiz
        </Link>
      </div>
      <div className="headerLink">
        <a href={DownloadFile} download>
          Download
        </a>
      </div>
      <div className="headerLink more">
        <Link to="/teachone/more" className={more_class}>
          More Resources
        </Link>
      </div>
      <div className="headerLink">
        <a href={EMAIL_HREF}>
          Share
        </a>
      </div>
    </div>
  );
}

function doOneSubNav(practice1, practice2) {
  return (
    <div className="doOneSubNav">
      <div className="headerLink">
        <Link to="/doone/practice1" className={practice1}>
          Practice: Mifepristone Day
        </Link>
      </div>
      <div className="resources">
        <div className="resourceLink">
          <a href="http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf" target="_blank" rel="noopener noreferrer">View medication guide</a>
        </div>
        <div className="resourceLink">
          <a href="http://www.earlyoptionpill.com/wp-content/uploads/2016/02/Patient-Agreement-Form-March2016-1.pdf" download="consent_guide.pdf" target="_blank" rel="noopener noreferrer">Download patient agreement</a>
        </div>
      </div>
      <div className="headerLink">
        <Link to="/doone/practice2" className={practice2}>
          Practice: Follow-up Day
        </Link>
      </div>
      <div className="headerLink">
        <a href="https://www.reproductiveaccess.org/resource/order-mifepristone/" target="_blank" rel="noopener noreferrer">How to Order mife</a>
      </div>
    </div>
  );

}
function Sidebar(props) {
  const theme = props.stage;
  const titleClass = "title " + props.stage;
  const section = props.section;
  let icon = null;
  let altText = "";
  let subtitle = "";
  let practice1 = "";
  let practice2 = "";
  let subnav = null;
  switch (theme) {
    case "SeeOne":
      icon = SeeOneIcon;
      altText = "SEE?"
      //subtitle = "Click on the videos in any order to see a typical doctor/patient visit"
      break;
    case "DoOne":
      altText = "DO!"
      icon = DoOneIcon;
      if (section === "practice1") {
        practice1 = "practice disabled"
        practice2 = "practice unselected"
        subnav = doOneSubNav(practice1, practice2);
      } else if (section === "practice2") {
        practice1 = "practice unselected"
        practice2 = "practice disabled"
        subnav = doOneSubNav(practice1, practice2);
      }
      break;
    case "TeachOne":
      altText = "TEACH!"
      icon = TeachOneIcon;
      if (section !== "congrats") {
        subnav = teachOneSubNav(props);
      }
      break;
    default:
     break;
  }

  return (
    <div className={'Sidebar ' + theme} id="Sidebar">
      <img src={icon} alt={altText} className={theme} />
      <div className={titleClass}>
        {altText}
      </div>
      { subtitle && 
      <div className="subtitle">
        <p>
        {subtitle}
        </p>
        <div className="arrow-right" />
      </div> }
      { section && 
      <div className="subnav">
        {subnav}
      </div> }
    </div>
  );
}

export default Sidebar;
