import React from 'react';
import './QuizNav.scss';

function navOptions (questionNum, clickHandler, startNum, endNum) {
  return (
      Array(endNum).fill().map((_, i) => (<div key={i+startNum} onClick={() => {clickHandler(i+startNum)}} className={"qnum " + ((i+startNum)===questionNum).toString()}>{i+startNum+1}</div>))
  );
}

function QuizNav(props) {
  return (
    <div className="QuizNav">
      <div className="desktop">
        {navOptions(props.questionNum, props.clickHandler, 0, 20)}
      </div>
      <div className="mobile row1">
        {navOptions(props.questionNum, props.clickHandler, 0, 10)}
      </div>
      <div className="mobile row2">
        {navOptions(props.questionNum, props.clickHandler, 10, 10)}
      </div>
    </div>
  );
}

export default QuizNav;
