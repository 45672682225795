import React from "react";
import { Link } from "react-router-dom";
import "./Introduction.scss";
import EarnCMECredit from "./earncmecredit.png";
import TeachLogo from "./logos/TEACH_white.png";
import UCDLogo from "./logos/UCDavis_white.png";
import SFPLogo from "./logos/SFP_white.png";
import BrownLogo from "./logos/Brown_white.png";
import BixbyLogo from "./logos/Bixby_white.png";
import ChromeLogo from "./ChromeIcon.png";
import MifepristoneOverviewDoc from "./DoOne/MifepristoneOverview.docx";
//import ReactGA from 'react-ga';

class Introduction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisclaimer: false,
    };
  }

  componentDidMount() {
    //ReactGA.pageview("/")
  }

  handleShowDisclaimer = () => {
    this.setState({ showDisclaimer: true });
  };

  getDisclosure = () => {
    return (
      <div id="disclosure">
        <h2> Disclosures &amp; Acknowledgements </h2>
        <p>
          It is the policy of the University of California Schools of Medicine
          to ensure balance, independence, objectivity and scientific rigor. All
          persons involved in the selection, development and presentation of
          content are required to disclose any real or apparent conflicts of
          interest.{" "}
        </p>
        <p>
          <strong>Disclosures:</strong> NONE of the following faculty, project
          staff or reviewers have any financial relationships with commercial
          interests relevant to the content of this activity.{" "}
        </p>
        <p>
          <strong>Funding</strong>
          <strong>:</strong> Society of Family Planning Research Fund
        </p>
        <ul>
          <strong>Faculty:</strong>
          <li>
            {" "}
            <strong>Eleanor Bimla Schwarz, MD, MS, Course Director</strong>{" "}
            Professor of Medicine, University of California, Davis{" "}
          </li>
          <li>
            <strong>Mindy Sobota, MD, MS, MPhil</strong> Associate Professor of
            Medicine, Clinician Educator, Brown University
          </li>
          <li>
            <strong>Suzan Goodman, MD, MPH</strong>{" "}
            <span>
              Associate Clinical Professor of Family and Community Medicine,
              UCSF; TEACH Program Curriculum Director; UCSF Bixby Center for
              Global Reproductive Health
            </span>
          </li>
        </ul>
        <ul>
          <strong>Clinical Reviewers:</strong>
          <li>
            <strong>Jessica Beaman, MD, MPH,</strong> Assistant Professor of
            Medicine, UC San Francisco
          </li>
          <li>
            <strong>Courtney Benedict, CNM, MSN,</strong> Associate Director
            Medical Standards Implementation
            <br /> Planned Parenthood Federation of America{" "}
          </li>
          <li>
            <strong>Rachel S. Casas, MD, EdM,</strong> Assistant Professor of
            Medicine; Penn State College of Medicine
          </li>
          <li>
            <strong>Cynthia H. Chuang, MD, MSc,</strong> Chief, Division of
            General Internal Medicine
            <br /> Professor of Medicine and Public Health Sciences, Penn State
            College of Medicine
          </li>
          <li>
            <strong>Daniel Grossman, MD,</strong> Professor, Department of
            Obstetrics, Gynecology &amp; Reproductive Sciences; Director,
            Advancing New Standards in Reproductive Health (ANSIRH), UC San
            Francisco
          </li>
          <li>
            <strong>Ivonne McLean, MD,</strong> Faculty Attending, Community
            Family Health Center, New York; Reproductive Health Access Project
            (RHAP) Provider
          </li>
          <li>
            <strong>Christine Prifti, MD,</strong> Instructor of Medicine,
            Boston University School of Medicine
          </li>
        </ul>
        <p>
          <strong>Web Development: </strong>Nitid Bit, LLC
        </p>
        <p>
          <strong>Video Producer/Editor: </strong>Luke Walden
        </p>
        <p>
          <strong>Director of Photography: </strong>Charlotte Hornsby
        </p>
        <p>
          <strong>Sound Recordist: </strong>Alistair Farrant
        </p>
        <p>
          <strong>Acting: </strong>Julia Register
        </p>
        <div className="statements">
          <p>
            <strong>Off-label Disclosure:</strong> This educational activity
            contains discussion of unlabeled uses of agents that are not
            approved by the FDA. Please consult the prescribing information for
            each product. <br />
          </p>
          <p>
            <strong>Cultural Competency:</strong> This activity complies with
            California Assembly Bill 1195 which requires CME courses with
            patient care components to include curriculum addressing cultural
            and linguistic competencies. <br />
            The views and opinions expressed in this activity are those of the
            faculty and do not necessarily reflect the views of the University
            of California, Brown University, or the Society of Family Planning.
          </p>
        </div>
        <div className="references">
          <ol>
            <strong>References:</strong>
            <li>
              Abbas D, et al. Outpatient medical abortion is safe and effective
              through 70 days gestation. Contraception. 2015. PMID: 26118638{" "}
            </li>
            <li>
              Achilles SL, et al. Prevention of infection after induced
              abortion: SFP guideline 20102. Society of Family Planning.
              Contraception. 2011. PMID: 21397086{" "}
            </li>
            <li>
              Aldrich T, et al. Does methotrexate confer a significant advantage
              over misoprostol alone for early medical abortion? A retrospective
              analysis of 8678 abortions. BJOG. 2007. PMID: 17439563
            </li>
            <li>
              Bearak JM, et al. Disparities and change over time in distance
              women would need to travel to have an abortion in the USA: a
              spatial analysis. Lancet Public Health. 2017. PMID: 29253373
            </li>
            <li>
              Chen AY, et al. Bleeding after medication-induced termination of
              pregnancy with two dosing schedules of mifepristone and
              misoprostol. Contraception. 2006 PMID:16531178{" "}
            </li>
            <li>
              Chen MJ, et al. Mifepristone With Buccal Misoprostol for
              Medical Abortion: A Systematic Review. Obstet Gynecol 2015. PMID:
              26241251{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://escholarship.org/uc/item/0v4749ss"
              >
                https://escholarship.org/uc/item/0v4749ss
              </a>
            </li>
            <li>
              Cleland K, et al. Significant adverse events and outcomes after
              medical abortion. Obstet Gynecol. 2013. PMID:23262942{" "}
            </li>
            <li>
              Coelho KE, et al. Misoprostol embryotoxicity: clinical evaluation
              of fifteen patients with arthrogryposis. Am J Med Genet. 2000.
              PMID: 11186880{" "}
            </li>
            <li>
              Dabash R, et al. A double-blind randomized controlled trial of
              mifepristone or placebo before buccal misoprostol for abortion at
              14-21 weeks of pregnancy. Int J Gynaecol Obstet 2015. PMID:
              25896965
            </li>
            <li>
              Davis A, et al. Bleeding patterns after early abortion with
              mifepristone and misoprostol or manual vacuum aspiration. Am Med
              Womens Assoc 2000.{" "}
            </li>
            <li>
              Dickinson JE, et al. Mifepristone and oral, vaginal, or sublingual
              misoprostol for second-trimester abortion: a randomized controlled
              trial. Obstet Gynecol 2014. PMID: 24807339{" "}
            </li>
            <li>
              Fiala C, et al. Verifying the effectiveness of medical abortion;
              ultrasound versus hCG testing. Eur J Obstet Gynecol Reprod Biol.
              2003. PMID: 12860340
            </li>
            <li>
              Fjerstad M, et al. Rates of serious infection after changes in
              regimens for medical abortion. NEJM. 2009. PMID: 19587339{" "}
            </li>
            <li>
              Friedlander EB, et al. Prophylactic Pregabalin to Decrease Pain
              During Medication Abortion: A Randomized Controlled Trial. Obstet
              Gynecol. 2018. PMID: 30095762{" "}
            </li>
            <li>
              Gatter M, et al. Efficacy and safety of medical abortion using
              mifepristone and buccal misoprostol through 63 days.
              Contraception. 2015. PMID: 25592080{" "}
            </li>
            <li>
              Guttmacher Institute, An Overview of Abortion Laws May 2019{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.guttmacher.org/state-policy/explore/overview-abortion-laws"
              >
                https://www.guttmacher.org/state-policy/explore/overview-abortion-laws
              </a>
            </li>
            <li>
              Guttmacher Institute, Report on State Abortion Policy{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.guttmacher.org/gpr/2019/05/improving-access-abortion-telehealth"
              >
                https://www.guttmacher.org/gpr/2019/05/improving-access-abortion-telehealth
              </a>
            </li>
            <li>
              Hollenbach SJ, et al. “Provoked” feto-maternal hemorrhage may
              represent insensible cell exchange in pregnancies from 6 to 22
              weeks gestational age” Contraception. 2019.{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.contraceptionjournal.org/article/S0010-7824(19)30129-5/fulltext"
              >
                https://www.contraceptionjournal.org/article/S0010-7824(19)30129-5/fulltext
              </a>
            </li>
            <li>
              Guest J, et al. Randomized controlled trial comparing the efficacy
              of same-day administration of mifepristone and misoprostol for
              termination of pregnancy with the standard 36 to 48-hour protocol.
              BJOG. 2007. PMID: 17305893
            </li>
            <li>
              Kapp N, et al. Medical abortion in the late first trimester: a
              systematic review. Contraception 2019. PMID: 30444970{" "}
            </li>
            <li>
              Livshits A, et al. Ibuprofen and paracetamol for pain relief
              during medical abortion: A double-blind randomized controlled
              study. Fertility and sterility. 2009. PMID:18359021
            </li>
            <li>
              Mark A, et al. Foregoing Rh testing and anti-D immunoglobulin for
              women presenting for early abortion: a recommendation from NAF’s
              Clinical Policies Committee. Contraception 2019. PMID: 30867121{" "}
            </li>
            <li>
              National Abortion Federation, Clinical Practice Guidelines{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://prochoice.org/resources/clinical-policy-guidelines/"
              >
                https://prochoice.org/resources/clinical-policy-guidelines/
              </a>
            </li>
            <li>
              Sheldon WR et al. Early abortion with buccal versus sublingual
              misoprostol alone: a multicenter, randomized trial. Contraception
              2019 PMID:30831103{" "}
            </li>
            <li>
              Raymond EG, et al. Prophylactic compared with therapeutic
              ibuprofen analgesia in first-trimester medical abortion: a
              randomized controlled trial. Obstet Gynecol. 2013. PMID:23921857.
            </li>
            <li>
              Raymond EG, et al. Sixteen Years of Overregulation: Time to
              Unburden Mifeprex. Mifeprex REMS Study Group. NEJM 2017. PMID:
              28225670{" "}
            </li>
            <li>
              Raymond EG, et al. Serial multilevel urine pregnancy testing to
              assess medical abortion outcome: a meta-analysis. Contraception.
              2017. PMID: 28041991
            </li>
            <li>
              Raymond EG, et al. Simplified medical abortion screening: a
              demonstration project. Contraception 2018. PMID: 29170088
            </li>
            <li>
              Raymond EG, et al. Efficacy of Misoprostol Alone for
              First-Trimester Medical Abortion: A Systematic Review. Obstet
              Gynecol 2019. PMID: 3053156{" "}
            </li>
            <li>
              Schreiber CA, et al. Mifepristone Pretreatment for the Medical
              Management of Early Pregnancy Loss. NEJM. 2018. PMID: 29874535.
            </li>
            <li>
              Society of Family Planning Clinical Guideline. Medical management
              of first-trimester abortion. Contraception 2014.{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://goo.gl/BJiETT"
              >
                http://goo.gl/BJiETT
              </a>
            </li>
            <li>
              Society of Family Planning Clinical Guidelines{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.societyfp.org/resources/clinical-guidelines"
              >
                https://www.societyfp.org/resources/clinical-guidelines
              </a>
            </li>
            <li>
              Upadhyay UD, et al. Incidence of emergency department visits and
              complications after abortion. Obstet Gynecol 2015. PMID: 25560122{" "}
            </li>
          </ol>
        </div>
      </div>
    );
  };

  render() {
    const disclaimerBlob = this.getDisclosure();
    return (
      <div id="Introduction">
        <div className="introContent">
          <div className="bestViewed">
            <img src={ChromeLogo} alt="ChromeLogo" className="chrome" />
            <p>Site best viewed on Chrome</p>
          </div>
          <div className="notFooters">
            <div className="introText">
              <h1>WELCOME</h1>
              <h3>Here you can learn evidence-based ways to:</h3>
              <ol>
                <li>
                  Evaluate patients prior to provision of mifepristone for early
                  abortion or miscarriage management
                </li>
                <li className="numberedList">
                  Effectively counsel patients regarding medication abortion
                </li>
                <li className="numberedList">
                  Discuss criteria to determine the need for additional clinical
                  services after use of mifepristone
                </li>
              </ol>
              <h3 className="earnCredits">
                CME credit is available for completing this 1 hour training
              </h3>
            </div>
            <div className="cmeOrNot">
              {" "}
              {/* Mike, replace these with button to click handle better*/}
              <div className="cme">
                <a href="https://cme-reg.configio.com/pd/4274/medication-abortion-in-primary-care?cid=2460&returncom=productlist&source=search">
                  <img src={EarnCMECredit} alt="EarnCMEcredit" />
                </a>
              </div>
              <div className="maybeLater">
                <Link to="/home" className="homeLink">
                  Start training
                  <div className="arrow-right"></div>
                </Link>
              </div>
            </div>
            <div className="overview">
              <a href={MifepristoneOverviewDoc} download>
                I’d like to read a course overview first >
              </a>
            </div>
            {this.state.showDisclaimer ? (
              <div className="disclaimerText">{disclaimerBlob}</div>
            ) : (
              <button
                className="disclaimerButton"
                onClick={this.handleShowDisclaimer}
              >
                <p>Disclosures &amp; Acknowledgements</p>
              </button>
            )}
          </div>
          <div className="footers">
            <div className="logos">
              <img src={UCDLogo} alt="UCD" className="logo" />
              <img src={BrownLogo} alt="Brown" className="logo" />
              <img src={TeachLogo} alt="Teach" className="logo" />
              <img src={BixbyLogo} alt="Bixby" className="logo" />
              <img src={SFPLogo} alt="SFP" className="logo" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Introduction;
