import React from 'react';
import { Link } from 'react-router-dom';
import './DoOnePractice.scss';
import MifepristoneOverviewDoc from './MifepristoneOverview.docx';
//import ReactGA from 'react-ga';

class DoOnePractice extends React.Component {
  constructor(props) {
    super(props)

    let questionNum = 1;
    let showAnswer = false;

    if (this.props.startFromEnd === true) {
      showAnswer = true;
      if (this.props.section === "practice1") {
          questionNum = 3;
      } else {
          questionNum = 2;
      }
    }

    this.state = {
      section: this.props.section,
      questionNum: questionNum,
      showReferences: false,
      userAnswer: "",
      showAnswer: showAnswer
    }
  }
 
  componentDidMount() {
    //ReactGA.pageview("/doone/practice")
  }

  handleNextQuestion = (e) => {
    if ((this.state.questionNum === 2) && (this.state.section === "practice2")) {
      this.props.history.push("/teachone")
    }
    else if (this.state.questionNum === 3) {
      this.props.nextSection()
    } else {
      const nextQuestionNum = this.state.questionNum + 1;
      this.setState({questionNum: nextQuestionNum,
                     userAnswer: "",
                     showReferences: false,
                     showAnswer: false })
      window.scrollTo(0, 0);
    }
  }

  handlePrevQuestion = (e) => {
    if ((this.state.questionNum === 1) && (this.state.section === "practice2")) {
      this.props.prevSection()
    } else {
      const nextQuestionNum = this.state.questionNum - 1;
      this.setState({questionNum: nextQuestionNum,
                     userAnswer: "",
                     showReferences: false,
                     showAnswer: true })
      window.scrollTo(0, 0);
    }
  }

  handleShowReferences = () => {
    this.setState({showReferences: !this.state.showReferences});
  }

  handleAnswerChange = (e) => {
    this.setState({userAnswer: e.target.value});
  }

  handleShowAnswer = () => {
    this.setState({showAnswer: true});
  }

  getAnswer1Section1 = () => {
    return (
      <div className="answerBlob">
        <ul className="part1">
          <li>Last menstrual period; history indicating pregnancy is &#8804; 77 days (11 weeks)</li>
          <li>No current clinical suspicion of ectopic or molar pregnancy</li>
          <li>Not anemic, anticoagulated or with a bleeding disorder</li>
          <li>No severe or unstable chronic condition</li>
          <li>No adrenal insufficiency (or chronic use of oral steroids)</li>
          <li>No porphyria</li>
          <li>No IUD in the uterus (must be removed first)</li>
          <li>No allergy to mifepristone or misoprostol</li>
          <li>Certain of desire to have abortion and willing and able to follow up as planned</li>
        </ul>
      </div>
    );
  }

  getReferences1Section1 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>Society of Family Planning Clinical Recommendations for Management of First-Trimester Abortion <a rel="noopener noreferrer" target="_blank" href="https://www.societyfp.org/resources/clinical-guidelines">https://www.societyfp.org/resources/clinical-guidelines</a></li>
          <li>Patient Medication Guide <a target="_blank" rel="noopener noreferrer" href="http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf">http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf</a></li>
          <li>2020 Clinical Practice Guidelines, National Abortion Federation, Available at: <a target="_blank" rel="noopener noreferrer" href="https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2020-CPGs-Final-for-web.pdf">https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2020-CPGs-Final-for-web.pdf</a></li>
        </ol>
      </div>
    );
  }


  getAnswerXSection1 = () => {
    return (
      <div className="answerBlob">
        <ul className="part1">
          <li>Pelvic exam is only indicated with symptoms or possible gestational age {">"} 10 weeks.</li>
        </ul>
      </div>
    );
  }

  getReferencesXSection1 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>Society of Family Planning Clinical Guideline: 2019 Medical Management of First Trimester Abortion <a target="_blank" rel="noopener noreferrer" href="https://www.societyfp.org/resources/clinical-guidelines">https://www.societyfp.org/resources/clinical-guidelines</a></li>
        </ol>
      </div>
    );
  }

  getAnswer2Section1 = () => {
    return (
      <div className="answerBlob">
        <ul className="part1">
          <li>Ultrasound is NOT required<sup>1</sup> unless:
            <ul>
              <li>Concern for ectopic or molar pregnancy</li>
              <li>Unsure of pregnancy dates or possible gestational age > 10weeks.</li>
            </ul>
          </li>
          <li>Pelvic exam is NOT indicated unless patient has symptoms.</li>
          <li>STI testing is NOT required unless patient has risk factors.</li>
          <li>Rh testing is NOT required for abortion before 12 weeks from last menstrual period, although historically was offered by many US clinicians.</li>
        </ul>
      </div>
    );
  }

  getReferences2Section1 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>Society of Family Planning Clinical Guideline: 2019 Medical Management of First Trimester Abortion <a target="_blank" rel="noopener noreferrer" href="https://www.societyfp.org/resources/clinical-guidelines">https://www.societyfp.org/resources/clinical-guidelines</a></li>
          <li>Foregoing Rh testing and anti-D immunoglobulin for women presenting for early abortion: a recommendation from the National Abortion Federation's Clinical Policies Committee.  Mark A, et al. Contraception. 2019 May; PMID: 30867121</li>
          <li>NAF Clinical Policy Guidelines 2022: <a target="_blank" rel="noopener noreferrer" href="https://prochoice.org/providers/quality-standards/">https://prochoice.org/providers/quality-standards/</a></li>
          <li>World Health Organization. Abortion Care Guideline. Geneva: World Health Organization; 2022. <a target="_blank" rel="noopener noreferrer" href="https://www.who.int/publications/i/item/9789240039483">https://www.who.int/publications/i/item/9789240039483</a></li>
        </ol>
      </div>
    );
  }

  getAnswer3Section1 = () => {
    return (
      <div className="answerBlob">
        <ul className="part1">
          <li>When and how to take the misoprostol (review medication guide)</li>
          <li>How to manage cramps (with ibuprofen and comfort measures)</li>
          <li>
          What number to call if
            <ul>
              <li>Soaking 2 maxi pads/hour for 2 consecutive hours</li>
              <li>Nausea or malaise > 24 hours after misoprostol</li>
              <li>Fever > 24 hours after misoprostol</li>
              <li>No bleeding at all 24 hours after misoprostol</li>
            </ul>
          </li>
          <li>No long-term adverse effects on health or fertility</li>
        </ul>
      </div>
    );
  }

  getReferences3Section1 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>National Abortion Federation 2018 Clinical Policy Guidelines <a target="_blank" rel="noopener noreferrer" href="https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2018_CPGs.pdf">https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2018_CPGs.pdf</a></li>
          <li>Society of Family Planning Clinical Recommendations for Management of First-Trimester Abortion <a target="_blank" rel="noopener noreferrer" href="https://www.societyfp.org/resources/clinical-guidelines">https://www.societyfp.org/resources/clinical-guidelines</a></li>
          <li>Patient Medication Guide <a target="_blank" rel="noopener noreferrer" href="http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf">http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf</a></li>
        </ol>
      </div>
    );
  }

  getAnswer1Section2 = () => {
    return (
      <div className="answerBlob">
        <p>Any of the following (assessed before and after taking medications; i.e. at visit 1 and visit 2): </p>
        <ul className="part1">
          <li>Clinical history and urine pregnancy testing (i.e., negative urine test 1 month after taking pills); some do this follow-up by phone or electronic messaging.<sup>1,2</sup></li>
          <li>Serum beta hCG quantitative (confirming a drop of 50% at 72 hours or 80% at 1 week <sup>1</sup>)</li>
          <li>Pelvic ultrasound (debris in the uterus is common after medication abortion, making serum betas the more reliable way to confirm that mifepristone worked.<sup>1</sup>)</li>
        </ul>
      </div>
    );
  }

  getReferences1Section2 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>Verifying the effectiveness of medical abortion; ultrasound versus hCG testing. Fiala C, et al. Eur J Obstet Gynecol Reprod Biol. 2003  PMID: 12860340</li>
          <li>National Abortion Federation 2018 Clinical Policy Guidelines <a target="_blank" rel="noopener noreferrer" href="https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2018_CPGs.pdf">https://5aa1b2xfmfh2e2mk03kk8rsx-wpengine.netdna-ssl.com/wp-content/uploads/2018_CPGs.pdf</a></li>
          <li>Serial multilevel urine pregnancy testing to assess medical abortion outcome: a meta-analysis. Raymond EG, et al. Contraception. PMID: 28041991</li>
        </ol>
      </div>
    );
  }


  getAnswer2Section2 = () => {
    return (
      <div className="answerBlob">
        <ul className="part1">
          <li>Soaking 2 maxi pads/hour for 2 hours in a row. Excessive bleeding with orthostatic hypotension, significant drop in hematocrit is very rare; only 0.03-0.06% of patients who take mifepristone need transfusion<sup>1</sup></li>
          <li> Nausea, malaise, weakness or abnormal vital signs >24 hours after taking misoprostol Need for IV antibiotics is extremely rare (0.006% to 0.093% of patients) However, Toxic Shock due to Clostridium Sordelli can be fatal requiring prompt evaluation of tachycardia, hypotension, leukocytosis, or hemo-concentration without fever <sup>2</sup></li>
          <li>Persistent bleeding/cramps – may need exam, labs +/- ultrasound to r/o:
            <ul>
              <li>Continuing pregnancy [Rare; 1.2-3.5%] <sup>1,3</sup></li>
              <li>Retained tissue [can re-dose misoprostol, {"<"} 4% require a procedure]<sup>1</sup></li>
              <li>Endometritis [Rare; 0.5-0.9% treated for infection]<sup>1</sup></li>
              <li>Ectopic or molar pregnancy [Rare; {"<"} 0.6%] <sup>3</sup></li>
            </ul>
          </li>
          <li>No significant bleeding - need US to r/o:
            <ul>
              <li>Continuing pregnancy [Rare; 1.2-3.5%] <sup>1,2</sup></li>
              <li>Ectopic [Rare; {"<"} 0.6%]<sup>3</sup></li>
            </ul>
          </li>
          <li>Concern for ectopic pregnancy [Rare; {"<"} 0.6%]<sup>4</sup></li>
        </ul>
      </div>
    );
  }

  getReferences2Section2 = () => {
    return (
      <div className="references">
        <ol className="part1">
          <li>Mifepristone with buccal misoprostol for medical abortion: a systematic review.  Chen MJ, et al. Obstet Gynecol 2015. <a target="_blank" rel="noopener noreferrer" href="https://escholarship.org/uc/item/0v4749ss">https://escholarship.org/uc/item/0v4749ss</a></li>
          <li>Rates of serious infection after changes in regimens for medical abortion.  Fjerstad M, et al., N Engl J Med 2009 PMID: 19887339.</li>
          <li>Outpatient medical abortion is safe and effective through 70 days gestation.  Abbas D, et al.  Contraception 2015 PMID: 26118638</li>
          <li>Incidence of emergency department visits and complications after abortion. Upadhyay UD, et al Obstet Gynecol. 2015 PMID: 25560122</li>
        </ol>
      </div>
    );
  }

  getHint1Section1 = () => {
    return (
      <div className="hint">
        <span>(Hint: check </span>
        <a href={MifepristoneOverviewDoc} download>this overview</a>
        <span>)</span>
      </div>
    );
  }

  getHint2Section1 = () => {
    return (
      <div className="hint">
        <span>(Hint: watch </span>
        <Link to="/seeone/video2?time=30" target="_blank">Mifepristone day video </Link>
        <span>)</span>
      </div>
    );
  }

  getHint3Section1 = () => {
    return (
      <div className="hint">
        <span>(Hint: check </span>
        <a href={MifepristoneOverviewDoc} download>this overview</a>
        <span>)</span>
      </div>
    );
  }

  getHint4Section1 = () => {
    return (
      <div className="hint">
        <span>(Hint: watch </span>
        <Link target="_blank" to="/seeone/video2">Mifepristone day video</Link>
        <span>)</span>
      </div>
    );
  }

  getHint1Section2 = () => {
    return (
      <div className="hint">
        <span>(Hint: check </span>
        <a href={MifepristoneOverviewDoc} download>this overview</a>
        <span>)</span>
      </div>
    );
  }

  getHint2Section2 = () => {
    return (
      <div className="hint">
        <span>(Hint: check </span>
        <a href={MifepristoneOverviewDoc} download>this overview</a>
        <span>)</span>
      </div>
    );
  }

  getHint3Section2 = () => {
    return (
      <div className="hint">
        <span>(Hint: check </span>
        <a href={MifepristoneOverviewDoc} download>this overview</a>
        <span>)</span>
      </div>
    );
  }
  

  render () {
    const { section,  questionNum, userAnswer, showAnswer, showReferences } = this.state

    const showBack = ((questionNum !== 1) || (section === "practice2"))
    let questionText = "";
    let hint = null;
    let answer = null;
    let visit = null;
    let references = null;
    switch (section) {
      case "practice1":
        visit = "Practice: Mifepristone Day";
        switch (questionNum) {
          case 1:
            questionText = "What history do you need?"
            answer = this.getAnswer1Section1();
            references = this.getReferences1Section1();
            hint = this.getHint1Section1();
            break;
          case 2:
            questionText = "What physical exam and testing do you need before administering mifepristone?"
            answer = this.getAnswer2Section1();
            references = this.getReferences2Section1();
            hint = this.getHint2Section1();
            break;
          case 3:
            questionText = "What topics do you discuss with a patient who would like a medication abortion?"
            answer = this.getAnswer3Section1();
            references = this.getReferences3Section1();
            hint = this.getHint3Section1();
            break;
          default:
            break;
        }
        break;
      case "practice2":
        visit = "Practice: Follow-up Day"
        switch (questionNum) {
          case 1:
            questionText = "How do you assess whether the medication abortion regimen worked?"
            answer = this.getAnswer1Section2();
            references = this.getReferences1Section2();
            hint = this.getHint1Section2();
            break;
          case 2:
            questionText = "What symptoms or signs require evaluation or treatment?"
            answer = this.getAnswer2Section2();
            references = this.getReferences2Section2();
            hint = this.getHint2Section2();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
        
    return (
      <div className="DoOnePractice">
        <div className="mobileOnly">
          <div className="do">
              <h2>
                Do!
              </h2>
            </div>
        </div>
        <div className="practice">
          <h3>{visit}</h3>
        </div>
        <div className="question">
          <div className="questionContainer">
            <span className="questionPreface">
              Question {questionNum} of 4:
            </span>
            <span className="questionText">
              {questionText}
            </span>
          </div>
          {hint} 
          <textarea value={userAnswer} placeholder="Type answer here" rows="10"  onChange={this.handleAnswerChange}>
          </textarea>
          <div className="answer">
            { showAnswer ?
              <div className="showAnswer">
                <p className="answer">Answer</p>
                {answer}

                { showReferences ?
                  <div>
                    <div className="showShowReferences" onClick={this.handleShowReferences}>
                      References
                    <div className="arrow-down"></div>
                  </div>
                    {references}
                  </div> :
                  <div className="showShowReferences" onClick={this.handleShowReferences}>
                    References
                    <div className="arrow-right"></div>
                  </div>
                }
                <div className="prevNext">
                  { showBack && 
                  <button className="hideAnswer prev" onClick={this.handlePrevQuestion}>
                    <div className="arrow-left" />
                    <p>BACK</p>
                  </button>
                  }
                  <button className={"hideAnswer next " + showBack} onClick={this.handleNextQuestion}>
                    <p>NEXT</p>
                    <div className="arrow-right" />
                  </button>
                </div>
              </div> :
              <button className="hideAnswer" onClick={this.handleShowAnswer}>
                <p>SHOW ANSWER</p>
                <div className="arrow-right" />
              </button>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default DoOnePractice;
