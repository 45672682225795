import React from 'react';
import { Link } from 'react-router-dom';
import './TeachOne.scss';
import NavMenu from '../shared/NavMenu.js';
import Sidebar from '../shared/Sidebar.js';
import ScrollToTopOnMount from '../shared/ScrollToTopOnMount.js';
import MoreResourcesIcon from './MoreResources.png';
import QuizIcon from './TeachOne_Quiz.png';
import ShareIcon from './TeachOne_Share.png';
import TeachOneIcon from '../Home_TeachOneIcon.png';
import TeachOneQuiz from './TeachOneQuiz.js';
import MobileNav from '../shared/MobileNav.js';
import TeachOneDots from './TeachOneDots_Center.png';
import { getMobileOperatingSystem, ANDROID_HREF, IOS_HREF, EMAIL_HREF } from '../shared/utils.js';
//import ReactGA from 'react-ga';

class TeachOne extends React.Component {

  componentDidMount() {
    //ReactGA.pageview("/teachone")
  }

  render () {
    const isAndroid = (getMobileOperatingSystem() === "Android");

    return (
      <div id="TeachOne">
        <ScrollToTopOnMount />
        <Sidebar stage="TeachOne" section={this.props.section} />
        <NavMenu showHome={this.props.showHome} showSeeOne={true} showDoOne={true} showTeachOne={true} />
        <MobileNav />
        <div className="teachContent" >
          { this.props.section ? 
            <TeachOneQuiz section={this.props.section} history={this.props.history} /> :
            <div>
              <div className="mobileOnly">
                <p>Teach!</p>
                <img src={TeachOneIcon} alt="Teach" id="teachOneIcon"/>
                <img src={TeachOneDots} alt="dots" className="mobileDots" />
              </div>
              <div className="teachLinks" >
                <Link to="/teachone/quiz" className="teachLink">
                  <img src={QuizIcon} alt="Quiz" />
                  <p className="teachlinkText" id="blah">Quiz</p>
                </Link>
                <img src={TeachOneDots} alt="dots" className="mobileDots" />
                <Link to="/teachone/more" className="teachLink">
                  <img src={MoreResourcesIcon} alt="More Resources" id="moreResourcesIcon"/>
                  <p className="narrow">More Resources</p>
                </Link>
                <img src={TeachOneDots} alt="dots" className="mobileDots" />
                  { isAndroid ? 
                  <a href={ANDROID_HREF} className="teachLink mobileOnly">
                    <img src={ShareIcon} className="mobileOnly" alt="share"/>
                    <p className="mobileOnly">Share</p>
                  </a> :
                  <a href={IOS_HREF} className="teachLink mobileOnly">
                    <img src={ShareIcon} className="mobileOnly" alt="share"/>
                    <p className="mobileOnly">Share</p>
                  </a> 
                  }
                  <a href={EMAIL_HREF} className="teachLink desktopOnly">
                    <img src={ShareIcon} alt="share" className="desktopOnly"/>
                    <p className="teachlinkText desktopOnly">Share</p>
                  </a>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default TeachOne;
