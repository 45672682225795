
import React from 'react';
import { Link } from 'react-router-dom';
import './SeeOne.scss';
import NavMenu from '../shared/NavMenu.js';
import Sidebar from '../shared/Sidebar.js';
import ScrollToTopOnMount from '../shared/ScrollToTopOnMount.js';
import Video1 from './video1.mp4';
import Video2 from './video2.mp4';
import Video3 from './video3.mp4';
import Video1Icon from './visit1.png';
import Video2Icon from './visit2.png';
import Video3Icon from './visit3.png';
import ReactPlayer from 'react-player'
import MobileNav from '../shared/MobileNav.js';
import SeeOneIcon from '../Home_SeeOneIcon.png';
import SeeOneDots from './SeeOne_Dots.png';
import SeeNextIcon from './SEE_Next.png';
import OriginalGuideThumbnail from './Original.png'
import GenericGuideThumbnail from './Generic.png'
import { getMobileOperatingSystem } from '../shared/utils.js';
import MifepristoneOverviewDoc from '../DoOne/MifepristoneOverview.docx';
//import ReactGA from 'react-ga';

const THUMBNAIL_WIDTH = "230px";
const THUMBNAIL_HEIGHT = "129px";
let FULL_WIDTH = "576px";
let FULL_HEIGHT = "324px";

const VIDEO2A_END_TIME = 163.8;

function isFullScreen() {
  return ( window.innerHeight === window.screen.height);
}

function isIE()
{
  var rv = -1;
  var ua = navigator.userAgent;
  if (navigator.appName === 'Microsoft Internet Explorer')
  {
    var re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  else if (navigator.appName == 'Netscape')
  {
    var re  = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  return (rv !== -1);
}

console.log('Is IE :', isIE());

window.mobileCheck = function() {
  const isAndroid = (getMobileOperatingSystem() === "Android");
  if (isAndroid) {
    return true;
  }
  var check = false;
  (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
  return check;
};

if (window.mobileCheck()) {
  FULL_WIDTH = "100%";
  FULL_HEIGHT = "100%";
}

class SeeOne extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      didOneTimeSeek: false,
      viewedMedicationGuide: this.props.previouslyViewedMedGuide,
      startedVideo1: false,
      startedVideo2: false,
      startedVideo3: false,
      video1IsPlaying: false,
      video2IsPlaying: false,
      video3IsPlaying: false,
      video1Width: THUMBNAIL_WIDTH,
      video1Height: THUMBNAIL_HEIGHT,
      video2Width: THUMBNAIL_WIDTH,
      video2Height: THUMBNAIL_HEIGHT,
      video3Width: THUMBNAIL_WIDTH,
      video3Height: THUMBNAIL_HEIGHT,
      showMedicationLink: false,
      pausingVideo2: false, 
      //video2: this.props.previouslyViewedMedGuide ? Video2 : Video2a
      video2: Video2
    }
  }

  exitFullscreen = ()  => {
    console.log("In exitFullScreen")
    if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } 
    console.log("Exiting exitFullScreen")
  }

  onPlay = (videoNum) => {
    switch (videoNum) {
      case 1:
        this.setState({video1Width: FULL_WIDTH,
                       video1Height: FULL_HEIGHT,
                       video1IsPlaying: true,
                       video2Width: THUMBNAIL_WIDTH,
                       video2Height: THUMBNAIL_HEIGHT,
                       video2IsPlaying: false,
                       video3Width: THUMBNAIL_WIDTH,
                       video3Height: THUMBNAIL_HEIGHT,
                       video3IsPlaying: false,
                       startedVideo1: true});
        this.player = this.player1;
        break;
      case 2:
        this.setState({video2Width: FULL_WIDTH,
                       video2Height: FULL_HEIGHT,
                       video2IsPlaying: true,
                       video1Width: THUMBNAIL_WIDTH,
                       video1Height: THUMBNAIL_HEIGHT,
                       video1IsPlaying: false,
                       video3Width: THUMBNAIL_WIDTH,
                       video3Height: THUMBNAIL_HEIGHT,
                       video3IsPlaying: false,
                       startedVideo2: true});
        this.player = this.player2;
        break;
      case 3:
        this.setState({video3Width: FULL_WIDTH,
                       video3Height: FULL_HEIGHT,
                       video3IsPlaying: true,
                       video1Width: THUMBNAIL_WIDTH,
                       video1Height: THUMBNAIL_HEIGHT,
                       video1IsPlaying: false,
                       video2Width: THUMBNAIL_WIDTH,
                       video2Height: THUMBNAIL_HEIGHT,
                       video2IsPlaying: false,
                       startedVideo3: true});
        this.player = this.player3;
        break;
      default:
        break;
    }
    if (this.props.timecode && !this.state.didOneTimeSeek) {
      this.player.seekTo(this.props.timecode)

      // Ensure that the seek worked. Doesn't work if the video is paused which seems
      // to happen even in this onPlay method when the video version of the page first loads
      if (this.player.getCurrentTime()) {
        this.setState({didOneTimeSeek: true})
      }
    }
  }

  onProgress = (progress) => {
    console.log(progress.playedSeconds);
    if (progress.playedSeconds > VIDEO2A_END_TIME) {
      this.setupMedicationLink()
    }
  }

  video2Ended = () => {
    console.log("in video2Ended")
    if (!this.state.viewedMedicationGuide) {
        this.setState({showMedicationLink: true,
                       pausingVideo2: true})
    }
    //this.exitFullscreen()
    this.onPause(2);
  }

  setupMedicationLink = () => {
    console.log("In setupMedicationLink")
    if (!this.state.viewedMedicationGuide && !window.mobileCheck()) {
      this.setState({showMedicationLink: true,
                     pausingVideo2: true})
      if (isFullScreen() || window.mobileCheck()) {
        this.exitFullscreen()
      }
      if (this.player.player.isPlaying) {
        // Player is janky about observing the 'playing' property. This is hack seems to work.
        console.log("Prevent over-playing")
        this.setState({pausingVideo2: false})

      }
    }
  }

  onPause = (videoNum) => {
    if ((videoNum === 2) && (this.player.getCurrentTime() > VIDEO2A_END_TIME)) {
      this.setupMedicationLink();
    }

    const showingInterstitial = this.state.showMedicationLink && !this.state.viewedMedicationGuide;
    switch (videoNum) {
      case 1:
        this.setState({video1Width: THUMBNAIL_WIDTH,
                       video1Height: THUMBNAIL_HEIGHT,
                       video1IsPlaying: false})
        break;
      case 2:
        if (window.mobileCheck() || !showingInterstitial) {
          // Only shrink the video if either on Mobile or we're not paused for the interstitial
          // This helps desktop users go back to the right place in the video. Mobile is just awkward.
          this.setState({video2Width: THUMBNAIL_WIDTH,
                         video2Height: THUMBNAIL_HEIGHT,
                         video2IsPlaying: false})
        }
        break;
      case 3:
        this.setState({video3Width: THUMBNAIL_WIDTH,
                       video3Height: THUMBNAIL_HEIGHT,
                       video3IsPlaying: false})
        break;
      default:
        break;
    }
    /*
    if (isFullScreen()) {
      this.exitFullscreen()
    }
    */
    //window.scroll(0, 0)
  }

  componentDidMount() {
    //ReactGA.pageview("/seeone")
    this.scrollToBottom();
    if (this.props.video === "1") {
      this.onPlay(1);
    } else if (this.props.video === "2") {
      this.onPlay(2);
    } else if (this.props.video === "3") {
      this.onPlay(3);
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.el && !isIE()) {
      this.el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  ref = player => {
    this.player = player
  }

  handleInterstitialClick = (linkType) => {
    console.log("In handleInterstitialClick")
    this.setState({showMedicationLink: false,
                   viewedMedicationGuide: true
                   })
    if (linkType === 'original') {
      window.open('http://www.earlyoptionpill.com/wp-content/uploads/2016/01/DAN_MedGuideEng_FINAL.pdf', '_blank')
    } else {
      window.open('https://genbiopro.com/wp-content/uploads/2019/05/GenBioPro-Medication-Guide.pdf', '_blank')
    }
    window.focus()
    this.props.setViewedMedicationGuide(true)
    return false;
  }

  render () {
    const { showMedicationLink, video2,
            video1Width, video1Height, video2Width, 
            video2Height, video3Width, video3Height, 
            video1IsPlaying, video2IsPlaying, video3IsPlaying, 
            startedVideo1, startedVideo2, startedVideo3,
            pausingVideo2} = this.state;
    const video1Class = video1IsPlaying ? "video playing" : "video";
    const video2Class = video2IsPlaying ? "video playing" : "video";
    const video3Class = video3IsPlaying ? "video playing" : "video";

    // Show the Next link if they've seen all 3 videos
    const readyForDo = startedVideo1 && startedVideo2 && startedVideo3 && !video1IsPlaying && !video2IsPlaying && !video3IsPlaying;

    const interstitialDisplay = showMedicationLink ? "display" : "hide";

    const overviewHide = video1IsPlaying || video2IsPlaying || video3IsPlaying ? ' hidden' : '';

    return (
      <div id="SeeOne">
        <ScrollToTopOnMount />
        <Sidebar stage="SeeOne" />
        <NavMenu showHome={this.props.showHome} showSeeOne={true} showDoOne={true} showTeachOne={true} />
        <MobileNav />
        <div className="mobileOnly">
          <p>SEE?</p>
          <img src={SeeOneIcon} alt="See One" />
          <div>
            <img src={SeeOneDots} alt="See One" />
          </div>
        </div>
        <div className={`interstitial ${interstitialDisplay}`}>
            <h2 className="line1">View what the patient is asked to read</h2>
            <h2 className="line2">before watching the rest of the video.</h2>
            <p className="information">Although the formatting differs, the information in both versions is identical.</p>
            <p className="click">Click one:</p>
            <div className="images">
              <div className="imageWithLabel original" onClick={() => this.handleInterstitialClick("original")}>
                <p>Original</p>
                <img src={OriginalGuideThumbnail} alt="Original Guide Thumbnail" className="guideThumbnail"/>
              </div>
              <div className="imageWithLabel generic" onClick={() => this.handleInterstitialClick("generic")}>
                <p>Generic</p>
                <img src={GenericGuideThumbnail} alt="Generic Guide Thumbnail" className="guideThumbnail"/>
              </div>
            </div>
        </div>
        <div className="videos" >
          <div className={video1Class}>
            <ReactPlayer url={Video1} 
                        ref={rp => this.player1 = rp}
                        onPlay={() => {this.onPlay(1)}}
                        onPause={() => {this.onPause(1)}}
                        onEnded={() => {this.onPause(1)}}
                        playing
                        light={Video1Icon} 
                        controls={true} 
                        height={video1Height}
                        width={video1Width} />
            <div className="label">
              Mentioning Mifepristone
            </div>
            <img src={SeeOneDots} alt="See One" className="mobileDots"/>
          </div>
          <div className={video2Class}>
            <ReactPlayer url={video2} 
                        ref={rp => this.player2 = rp}
                        onPlay={() => {this.onPlay(2)}}
                        onPause={() => {this.onPause(2)}}
                        onEnded={this.video2Ended}
                        onProgress={this.onProgress}
                        playing={!pausingVideo2}
                        light={Video2Icon} 
                        controls={true} 
                        height={video2Height}
                        width={video2Width} />
            <div className="label">
              Mifepristone Day
            </div>
            <img src={SeeOneDots} alt="See One" className="mobileDots"/>
          </div>
          <div className={video3Class}>
            <ReactPlayer url={Video3} 
                        ref={rp => this.player3 = rp}
                        onPlay={() => {this.onPlay(3)}}
                        onPause={() => {this.onPause(3)}}
                        onEnded={() => {this.onPause(3)}}
                        playing
                        light={Video3Icon} 
                        controls={true} 
                        height={video3Height}
                        width={video3Width} />
            <div className="label">
              Follow-up Day
            </div>
          </div>
        </div>
        <div className="links">
          <div className={`overview ${overviewHide}`}>
             <a href={MifepristoneOverviewDoc} download>Download overview ></a>
          </div>
          { readyForDo && 
          <div className="nextSection" ref={el => { this.el = el; }}>
            <Link to="/doone">
              <img src={SeeNextIcon} alt="Next Section" />
            </Link>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default SeeOne;
