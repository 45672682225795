import React from 'react';
import { Link } from 'react-router-dom';
import './DoOne.scss';
import NavMenu from '../shared/NavMenu.js';
import Sidebar from '../shared/Sidebar.js';
import ScrollToTopOnMount from '../shared/ScrollToTopOnMount.js';
import DoOnePractice from './DoOnePractice.js';
import Practice1Icon from './DOone_Practice1_icon.png';
import Practice2Icon from './DOone_Practice2_icon.png';
import OrderIcon from './DOone_OrderIcon.png';
import MobileNav from '../shared/MobileNav.js';
import DoOneIcon from '../Home_DoOneIcon.png';
import DoOneDots from './DOone_Dots.png';
//import ReactGA from 'react-ga';

class DoOne extends React.Component {

  componentDidMount() {
    //ReactGA.pageview("/doone")
  }

  render () {
    return (
      <div id="DoOne">
        <ScrollToTopOnMount />
        <Sidebar stage="DoOne" section={this.props.section} />
        <NavMenu showHome={this.props.showHome} showSeeOne={true} showDoOne={true} showTeachOne={true} />
        <MobileNav />
        <div className="doContent" >
          { this.props.section ? 
            <DoOnePractice section={this.props.section} history={this.props.history} nextSection={this.props.nextSection} prevSection={this.props.prevSection} startFromEnd={this.props.startFromEnd}/> :
            <div>
              <div className="mobileOnly">
                <p>Do!</p>
                <img src={DoOneIcon} alt="Do One" />
                <div>
                  <img src={DoOneDots} alt="dots" />
                </div>
              </div>
              <div className="doLinks" >
                <div className="link">
                  <Link to="/doone/practice1" className="practice1">
                    <img src={Practice1Icon} alt="Practice 1" />
                    <p className="practice">Practice</p>
                    <p>Mifepristone Day</p>
                  </Link>
                  <img src={DoOneDots} alt="dots" className="mobileDots"/>
                </div>
                <div className="link">
                  <Link to="/doone/practice2" className="practice2">
                    <img src={Practice2Icon} alt="Practice 2" />
                    <p className="practice">Practice</p>
                    <p>Follow-up Day</p>
                  </Link>
                  <img src={DoOneDots} alt="dots" className="mobileDots"/>
                </div>
                <div className="link">
                  <a href="https://www.reproductiveaccess.org/resource/order-mifepristone/" rel="noopener noreferrer" target="_blank">
                    <img src={OrderIcon} alt="How to Order"/>
                  </a>
                  <span className="label3">
                    How to Order
                  </span>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default DoOne;
