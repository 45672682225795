
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const SHARE_BODY = encodeURIComponent("I am sending the following helpful content to you: http://abortionpillcme.teachtraining.org");
const ANDROID_HREF = `sms://?body=${SHARE_BODY}`
const IOS_HREF = `sms:&body=${SHARE_BODY}`
const EMAIL_HREF = `mailto:?to=&body=${SHARE_BODY}&subject=Mifepristone training`
const CONTACT_HREF = "mailto:abortionpillcme@gmail.com"

export { getMobileOperatingSystem, ANDROID_HREF, IOS_HREF, EMAIL_HREF, CONTACT_HREF }