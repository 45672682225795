import React from 'react';
import './Course.scss';
import SeeOneIcon from './Home_SeeOneIcon.png';
import DoOneIcon from './Home_DoOneIcon.png';
import TeachOneIcon from './Home_TeachOneIcon.png';
import MobileNav from './shared/MobileNav.js';
import { Link } from 'react-router-dom';
import OneIcon from './1.png';
import TwoIcon from './2.png';
import ThreeIcon from './3.png';

class Course extends React.Component {

  render () {
    return (
      <div className="Course">
        <MobileNav />
        <div className="headerText">
          <h3>
            Mifepristone is safe, effective, and simple to prescribe.
          </h3>
          <h3>
            Click below to begin training.
          </h3>
        </div>
        <div className="homeButtons">
          <div className="homeButtonLink">
            <img className="number" src={OneIcon} alt="One" />
            <Link to="/seeone" className="link">
              <img src={SeeOneIcon} alt="See" />
              <div className="homeLabels" id="SeeOneLabel">See?</div>
            </Link>
          </div>
          <div className="homeButtonLink">
            <img className="number" src={TwoIcon} alt="Two" />
            <Link to="/doone" className="link">
              <img src={DoOneIcon} alt="Do" />
              <div className="homeLabels" id="DoOneLabel">Do!</div>
            </Link>
          </div>
          <div className="homeButtonLink">
            <img className="number" src={ThreeIcon} alt="Three" />
            <Link to="/teachone" className="link">
              <img src={TeachOneIcon} alt="Teach"/>
              <div className="homeLabels" id="TeachOneLabel">Teach!</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Course;
